import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { selectCloud } from "../reducers/cloudReducer"
import { useHistory } from "react-router-dom"

import Paper from "@mui/material/Paper"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import ListItemAvatar from "@mui/material/ListItemAvatar"
import Avatar from "@mui/material/Avatar"
import Container from "@mui/material/Container"
import Divider from "@mui/material/Divider"


const CloudListItem = ({ cloud, onSelect }) => {
  const history = useHistory()

  const doSelect = async (cloud) => {
    await onSelect(cloud.id)
    history.push("/")
  }

  return (
    <ListItem onClick={(e) => doSelect(cloud)}>
      <ListItemAvatar>
        <Avatar
          src={`data:image/png;base64,${cloud.logo}`}
          alt={cloud.name}
          variant="rounded"
        />
      </ListItemAvatar>
      <ListItemText>{cloud.name}</ListItemText>
    </ListItem>
  )
}

const SelectCloud = (props) => {
  return (
    <Paper elevation={8}>
      <Container>
        <h3>Valitse kirjanpito</h3>
        <Divider />
        <List>
          {props.clouds &&
            props.clouds.map((cloud) => (
              <CloudListItem
                key={cloud.id}
                cloud={cloud}
                onSelect={props.selectCloud}
              />
            ))}
        </List>
      </Container>
    </Paper>
  )
}

const mapStateToProps = (state) => {
  return {
    clouds: state.cloud ? state.cloud.login.clouds : [],
  }
}

export default connect(mapStateToProps, { selectCloud })(SelectCloud)
