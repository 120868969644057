import { useTranslation } from "react-i18next"

import { connect } from "react-redux"
import { putVoucher } from "../services/cloudService"
import { reload } from "../reducers/cloudReducer"

import CheckIcon from "@mui/icons-material/Check"
import DoneAllIcon from "@mui/icons-material/DoneAll"
import CloseIcon from "@mui/icons-material/Close"
import EditIcon from "@mui/icons-material/Edit"

import Grid from "@mui/material/Grid"
import Button from "@mui/material/Button"

import { useHistory } from "react-router-dom"
import { useSnackbar } from "notistack"

import { greenButton, yellowButton, redButton} from "./colorbuttons"
import { isEditable } from "../utils/makeDocument"
import { editVoucher } from "../reducers/newVoucherReducer"

import Defines from "../defines"

const VoucherHeaderButtons = (props) => {
  const { t } = useTranslation("voucherHeaderButtons", "voucherStatus")
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()

  const saveToStatus = async (status) => {
    try {
      console.log("To status", status)
      const json = props.data
      json.tila = status
      await putVoucher(json)
      await props.reload()
      history.goBack()
      enqueueSnackbar(
        t("successed") + " " + t("voucherStatus:" + status).toLowerCase(),
        { variant: "success" }
      )
    } catch (e) {
      console.log(e.stack)
      enqueueSnackbar(t("failed"), { variant: "error" })
    }
  }

  const edit = () => {
    props.editVoucher(props.data)
    history.push("/edit")
  }

  return (
    <Grid container alignItems="flex-end" direction="columns-reverse">
      {props.data.tila < Defines.VoucherStatus.Accepted &&
        props.rights.includes("Kh") && (
          <Button
            fullWidth
            variant="contained"
            sx={greenButton}
            startIcon={<DoneAllIcon />}
            onClick={() => saveToStatus(Defines.VoucherStatus.Accepted)}
          >
            {t("accept")}
          </Button>
        )}
      {props.data.tila < Defines.VoucherStatus.Checked &&
        props.rights.includes("Kt") && (
          <Button
            variant="contained"
            fullWidth
            startIcon={<CheckIcon />}
            sx={yellowButton}
            onClick={() => saveToStatus(Defines.VoucherStatus.Checked)}
          >
            {t("check")}
          </Button>
        )}
      {props.data.tila >= Defines.VoucherStatus.Received &&
        props.data.tila < Defines.VoucherStatus.Accepted && (
          <Button
            fullWidth
            variant="contained"
            startIcon={<CloseIcon />}
            sx={redButton}
            color="secondary"
            onClick={() => saveToStatus(Defines.VoucherStatus.Rejected)}
          >
            {t("reject")}
          </Button>
        )}

      {props.data.tila === Defines.VoucherStatus.Accepted &&
        props.rights.includes("Tt") && (
          <Button
            fullWidth
            variant="contained"
            startIcon={<DoneAllIcon />}
            sx={greenButton}
            onClick={() => saveToStatus(Defines.VoucherStatus.Booked)}
          >
            {t("book")}
          </Button>
        )}

      {props.editable &&  (
        <Button
          fullWidth
          variant="outlined"
          startIcon={<EditIcon />}
          onClick={() => edit()}
        >
          {t("edit")}
        </Button>
      )}
    </Grid>
  )
}

const mapStateToButtonProps = (state) => {
  return {
    rights: state.cloud.current.rights,
    periods: state.cloud.init.tilikaudet,
    settings: state.cloud.init.asetukset,
  }
}

export default connect(mapStateToButtonProps, { reload, editVoucher })(
  VoucherHeaderButtons
)
