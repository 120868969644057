import React from "react"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"

import LoginForm from "./LoginForm"
import MyPage from "./MyPage"
import SaldoView from "./SaldoView"
import ReportView from "./ReportView"
import CycleList from "./CycleList"
import NewVoucher from "./NewVoucher"
import AddonList from "./AddonList"

import CssBaseline from "@mui/material/CssBaseline"
import Divider from "@mui/material/Divider"

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom"

import VoucherList from "./VoucherList"
import Voucher from "./Voucher"
import EntryList from "./EntryList"

import MyAppBar from "./MyAppBar"
import TwoFaForm from "./TwoFaForm"

class App extends React.Component {
  render() {
    return (
      <React.Fragment>
        <CssBaseline/>
        <Router>
          <MyAppBar />
          <p />
          <Switch>
            <Route path="/login">
              { this.props.req2fa ? <TwoFaForm/> : <LoginForm /> }
            </Route>
            {!this.props.name && <Redirect to="/login" />}
            <Route path="/my" component={MyPage} />
            {!this.props.current && <Redirect to="/my" />}
            <Route path="/saldo">
              <SaldoView />
            </Route>
            <Route path="/reports">
              <ReportView />
            </Route>
            <Route path="/vouchers/:id">
              <Voucher />
            </Route>
            <Route path="/vouchers">
              <VoucherList />
            </Route>
            <Route path="/cycle">
              <CycleList />
            </Route>
            <Route path="/entries/:account">
              <EntryList />
            </Route>
            <Route path="/entries">
              <EntryList />
            </Route>
            <Route path="/new">
              <NewVoucher />
            </Route>
            <Route path="/edit">
              <NewVoucher />
            </Route>
            <Route path="/addons">
              <AddonList />
            </Route>  
            <Route path="/">
              <Redirect to="/saldo" />
            </Route>            
          </Switch>          
          <p align="center">WebKitsas &copy; Kitsas Oy 2021–2022</p>
        </Router>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {    
    name: state.cloud && state.cloud.login.user ? state.cloud.login.user.name : null,
    req2fa: state.cloud ? state.cloud.login.request2fa : null,
    current: state.cloud ? state.cloud.current : null,
    tasks:
      state.cloud && state.cloud.worklist ? state.cloud.worklist.length : null,
  }
}

export default withTranslation("app")(connect(mapStateToProps, {})(App))
