import React, { useEffect, useState } from "react" 
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"

import axios from "axios"
import { getConfig, getUrl } from "../services/cloudService"

import Paper from "@mui/material/Paper"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"

import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import Checkbox from "@mui/material/Checkbox"


import SelectPeriod from "./SelectPeriod"
import SelectSector from "./SelectSector"
import SelectReport from "./SelectReport"

import Euro from "./Euro"
import { FormControlLabel } from "@mui/material"
import { useHistory } from "react-router"


const ReportItem = (props) => {

    const history = useHistory()
    
    const row = props.row
    const indent = row.S ? row.S * 2 : 0 

    return (
        <React.Fragment>
            { row.V && <TableRow><TableCell>&nbsp;</TableCell><TableCell>&nbsp;</TableCell><TableCell>&nbsp;</TableCell></TableRow>}
            <TableRow>            
                <TableCell>    
                    {Array(indent).fill(1).map((el,i) => <span key={i}>&nbsp;</span>)}                                            
                    { row.M === "bold" ? (<b>{row.teksti}</b> ) : (<span>{row.teksti}</span>)}                
                </TableCell>                
                { row.summat && row.summat.map((euro, i) => (
                    <TableCell key={i} align="right">
                        { row.M === "bold" ? (<b><Euro value={euro}/></b> ) : (<Euro value={euro}/>)}                                    
                    </TableCell>
                ))}
                { !row.summat && <TableCell></TableCell>}                
            </TableRow>
            { row.erittely && props.detailed && row.erittely.map((d, i) => (
                <TableRow key={i} onClick={() =>  history.push("/entries/" + d.tili) }>
                    <TableCell style={{color:"darkslategray"}}>{Array(indent+6).fill(1).map((el,i) => <span key={i}>&nbsp;</span>)} {d.tili} {d.nimi}</TableCell>                    
                    { d.summat.map((deuro,di) => (
                        <TableCell key={di} align="right" style={{color: "darkslategray" }}><Euro value={deuro}/></TableCell>
                    )) }                
                </TableRow>
            ))}
        </React.Fragment>
    )
}

const ReportView = (props) => {

    const [report, setReport] = useState({})    
    const [detailed, setDetailed] = useState(true)
    const {t} = useTranslation("reportView")

    useEffect(() => {
        if( props.period ) {
            const payload = {
                raportti: props.currentReport,
                kieli: "fi",
                tyyppi: props.currentReport.startsWith("tase") ? "tase" : "tuloslaskelma",
                sarakkeet: [
                    {
                        tyyppi: "toteutunut",
                        alkupvm: props.period.alkaa,
                        loppupvm: props.period.loppuu
                    }
                ],
                erittely: true
            }
            if( props.sector > -1 && props.currentReport.startsWith("tulos/")) {
                payload.kohdennus = props.sector                
            }
            
            axios.post(
                getUrl("/raportti"),
                payload,
                getConfig()
            ).then( response => {
                setReport(response.data)
                console.log("Received report", response.data)
            })
        }        
    }, [props.updated, props.period, props.sector, props.currentReport])

    return (
                <Container text>
                    <Grid container>         
                        <Grid item sm={4} xs={12}>
                            <SelectReport/>
                        </Grid>               
                        <Grid item sm={3} xs={12}>
                            <SelectPeriod/>
                        </Grid>
                        { props.currentReport.startsWith("tulos/") &&
                            <Grid item sm={3} xs={12}>
                                <SelectSector />
                            </Grid>                        
                        }
                        <Grid item sm={2} xs={12}>
                            <FormControlLabel
                                label={t("detailed")}
                                control={
                                    <Checkbox
                                        checked={detailed}
                                        onChange={(event) => setDetailed(event.target.checked)}
                                        inputProps={{ 'aria-label' : 'controlled'}}
                                        color="default"
                                    />
                                }
                            />
                        </Grid>
                    </Grid>
                    <h2>{report.otsikko}</h2>                    
                    <Table>
                        <TableBody>
                            {report.raportti && report.raportti.map((item, i) => (
                                <ReportItem row={item} key={i} detailed={detailed}/>
                            ))}
                        </TableBody>
                    </Table>
                </Container>
    )
}

const mapStateToProps = (state) => {
    return {
        updated: state.cloud ? state.cloud.updated : null,
        period: state.cloud ? state.cloud.period : null,
        sector: state.cloud ? state.cloud.sector : -1,        
        currentReport: state.cloud ? state.cloud.report : "tulos/yleinen"
    }
}

export default connect(mapStateToProps, {})(ReportView)