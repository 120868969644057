import React from "react"
import { connect } from "react-redux"

import Paper from "@mui/material/Paper"
import Grid from "@mui/material/Grid"
import Button from "@mui/material/Button"
import Container from "@mui/material/Container"

import SendIcon from "@mui/icons-material/Send"
import CheckIcon from "@mui/icons-material/Check"
import DoneAllIcon from "@mui/icons-material/DoneAll"
import CloseIcon from "@mui/icons-material/Close"
import SaveAltIcon from "@mui/icons-material/SaveAlt"
import DoneOutlineIcon from "@mui/icons-material/DoneOutline"

import { useTranslation } from "react-i18next"
import Defines from "../defines"

import { makeDocument } from "../utils/makeDocument"
import { resetNewVoucher } from "../reducers/newVoucherReducer"
import { reload } from "../reducers/cloudReducer"
import { useHistory } from "react-router-dom"
import { saveDocument } from "../services/cloudService"


import { useSnackbar } from "notistack"
import dayjs from "dayjs"

import { greenButton, yellowButton, blueButton, redButton } from "./colorbuttons"




const NewVoucherButtons = (props) => {
  const { t } = useTranslation("newVoucherButtons")
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()

  const cancel = () => {
    props.resetNewVoucher()
    history.goBack()
  }

  const closeDaySetting = props.settings["TilitPaatetty"]
  const closeDay = closeDaySetting
    ? dayjs(closeDaySetting).add(1, "day")
    : dayjs("2020-01-01")
  const lastPeriod = props.periods[props.periods.length - 1]
  const maxDay = dayjs(lastPeriod.loppuu)
  const currentDay = dayjs(props.voucher.date)

  const dayInvalid =
    !currentDay.isValid() ||
    currentDay.isBefore(closeDay) ||
    currentDay.isAfter(maxDay)

  console.log("DI", props.voucher.date, closeDay, dayInvalid)

  const saveVoucher = async (status) => {
    const back = props.voucher.old.id ? -2 : -1
    const document = makeDocument(
      props.voucher,
      props.attachemnts,
      status,
      props.accounts
    )

    try {
      await saveDocument(document)
      enqueueSnackbar(t("saved"), { variant: "success" })
      props.resetNewVoucher()
      props.reload()
      history.go(back)
    } catch (e) {
      console.error(e)
      enqueueSnackbar(t("savefailed"), { variant: "error" })
    }
  }

  const oldStatus = props.voucher.old.tila ? props.voucher.old.tila : 0

  return (
    <Container maxWidth="xl">
      <Grid container spacing={1} direction="row" justifyContent="space-between" alignItems="stretch">          
        
        <Grid item md={3} sm={6} xs={12} xl={2}>
          <Button
            fullWidth
            variant="contained"
            color="secondary"     
            sx={redButton}                   
            startIcon={<CloseIcon />}
            onClick={() => cancel()}
          >
            {t("cancel")}
          </Button>
          </Grid>
          {(props.cycle === 0 ||
            oldStatus === Defines.VoucherStatus.Accepted) &&
            props.rights.includes("Tl") && (
              <Grid item md={3} sm={6} xs={12} xl={2}>
                <Button
                  fullWidth
                  variant="outlined"                  
                  disabled={dayInvalid}
                  sx={blueButton}
                  startIcon={<SaveAltIcon />}
                  onClick={() => saveVoucher(Defines.VoucherStatus.Draft)}
                >
                  {t("draft")}
                </Button>
              </Grid>
            )}
        
        
          {props.cycle > 0 &&
            props.rights.includes("Kl") &&
            oldStatus < Defines.VoucherStatus.Received && (
              <Grid item md={3} sm={6} xs={12} xl={2}>
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"                  
                  disabled={dayInvalid}
                  startIcon={<SendIcon />}
                  sx={blueButton}
                  onClick={() => saveVoucher(Defines.VoucherStatus.Received)}
                >
                  {t("cycle")}
                </Button>
              </Grid>
            )}

          {props.cycle > 0 &&
            props.rights.includes("Kt") &&
            oldStatus < Defines.VoucherStatus.Checked && (
              <Grid item md={3} sm={6} xs={12} xl={2}>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                sx={yellowButton}
                startIcon={<CheckIcon />}
                disabled={dayInvalid}
                onClick={() => saveVoucher(Defines.VoucherStatus.Checked)}
              >
                {t("check")}
              </Button>
              </Grid>
            )}
          {props.cycle > 0 &&
            props.rights.includes("Kh") &&
            oldStatus < Defines.VoucherStatus.Accepted && (
              <Grid item md={3} sm={6} xs={12} xl={2}>
              <Button
                fullWidth
                variant="contained"
                disabled={dayInvalid}
                startIcon={<DoneAllIcon />}
                sx={greenButton}
                onClick={() => saveVoucher(Defines.VoucherStatus.Accepted)}
              >
                {t("accept")}
              </Button>
              </Grid>
            )}
          {props.rights.includes("Tt") && (
            <Grid item md={3} sm={6} xs={12} xl={2}>
            <Button
              fullWidth
              variant="contained"
              sx={greenButton}
              disabled={dayInvalid}
              startIcon={<DoneOutlineIcon />}
              onClick={() => saveVoucher(Defines.VoucherStatus.Booked)}
            >
              {t("save")}
            </Button>
            </Grid>
          )}
        
      </Grid>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    voucher: state.newVoucher,
    type: state.newVoucher.type,
    cycle: state.newVoucher.cycle,
    rights: state.cloud.current.rights,
    accounts: state.cloud.init.tilit,
    attachemnts: state.attachments,
    settings: state.cloud.init.asetukset,
    periods: state.cloud.init.tilikaudet,
  }
}

export default connect(mapStateToProps, { resetNewVoucher, reload })(
  NewVoucherButtons
)
