import React, { useState } from "react"
import { connect } from "react-redux"
import { login2FA } from "../reducers/cloudReducer"
import Paper from "@mui/material/Paper"
import TextField from "@mui/material/TextField"
import Container from "@mui/material/Container"


import { useTranslation } from "react-i18next"

import { useHistory } from "react-router-dom"

import logo from "./webkitsas.png"

const TwoFaForm = (props) => {
    const [code, setCode] = useState("")
    const {t} = useTranslation("twofaform")
    const history = useHistory()


    const codeChanged = async (code) => {
        setCode(code)
        if( code.length===6) {            
            try {
                await props.login2FA(props.fakey, code)
                history.push("/")
            } catch(e) {
                console.log(e)
            }
        }
    }


    return (
        <Container maxwidth="md">
            <Paper elevation={8}>
                <Container text>
                    <p align="center">
                        <img src={logo} alt="webkitsas" width="80%" />
                    </p>
                    <h2>{props.name}</h2>
                    <p>{t("info")}</p>                    
                    <TextField
                        type="number"
                        label={t("code")}
                        value={code}
                        onChange={(e) => codeChanged(e.target.value)}
                        variant="outlined"
                        required
                        InputLabelProps={{shrink: true}}
                        fullWidth
                        margin="normal"
                    />                    
                </Container>
            </Paper>
        </Container>
    )
}

const mapStateToProps = (state) => {
    return {
        name: state.cloud.login.name,
        fakey: state.cloud.login.request2fa
    }
}

export default connect(mapStateToProps, { login2FA })(TwoFaForm)