import React from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"

import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import MenuList from '@mui/material/MenuList'
import InboxIcon from '@mui/icons-material/Inbox'

import Avatar from "@mui/material/Avatar"
import Badge from "@mui/material/Badge"

import { useTranslation } from "react-i18next/"


const MyAppBar = (props) => {
  const { t } = useTranslation("appBar")

  const [anchorElNav, setAnchorElNav] = React.useState(null)
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }



  return (
    <AppBar 
      position="static">        
        <Toolbar disableGlutters>
          <Typography
            variant="h6"
            nowrap
            component="div"
            sx={{ mr: 2, display: { xs: 'none', md: 'flex' }} }
          >
            {props.current ? props.current.name : t("webkitsas")}    
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none'} }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              { props.current && <MenuIcon /> }
            </IconButton>              
            { props.current && <Menu                
              id="menu-appbar"                
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'flex', md: 'none' }
              }}
            >                
              <MenuItem key="saldo" component={Link} to="/saldo" onClick={handleCloseNavMenu}>
                <Typography textAlign="center" variant="button">{t("saldos")}</Typography>
              </MenuItem>

              { props.current.rights.includes("Ra") && (
                <MenuItem key="reports" component={Link} to="/reports" onClick={handleCloseNavMenu} >
                  <Typography textAlign="center" variant="button">{t("reports")}</Typography>
                </MenuItem>
              )}

              { props.current.rights.includes("Ts") && (
                <MenuItem component={Link} to="/vouchers" onClick={handleCloseNavMenu}>
                  <Typography textAlign="center" variant="button">{t("vouchers")}</Typography>
                </MenuItem>
              )}

              { props.current.rights.includes("Ts") && (
                <MenuItem component={Link} to="/entries" onClick={handleCloseNavMenu}>
                  <Typography textAlign="center" variant="button">{t("entries")}</Typography>
                </MenuItem>
              )}
                  <MenuItem key="cycle" component={Link} to="/cycle" onClick={handleCloseNavMenu}>
                    <Typography textAlign="center" variant="button">{t("job")}</Typography>
                  </MenuItem>   
                  { props && props.addons > 0 && props.current && props.current.rights.includes("Ad") && (
                    <MenuItem key="addons" component={Link} to="/addons" onClick={handleCloseNavMenu}>
                      <Typography textAlign="center" variant="button">{t("addons")}</Typography>
                    </MenuItem>
              )}                    
            </Menu> }          
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }}}
          >
            {props.current ? props.current.name : t("webkitsas")}    
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }  }} justifyContent="center">
            {props.current && (
                <div>
                  <Button 
                    component={Link}
                    to="/saldo"        
                    style={{color: 'white'}}              
                    sx={{ my: 2, color: 'white', display: 'block' }}
                  >
                    {t("saldos")}
                  </Button>
                  {props.current.rights.includes("Ra") && (
                    <Button 
                      component={Link} 
                      to="/reports"
                      style={{color: 'white'}}  
                      sx={{ my: 2, color: 'white', display: 'block' }}
                    >
                      {t("reports")}
                    </Button>
                  )}
                  {props.current.rights.includes("Ts") && (
                    <Button 
                      component={Link} 
                      to="/vouchers"
                      style={{color: 'white'}}  
                      sx={{ my: 2, color: 'white', display: 'block' }}
                    >
                      {t("vouchers")}
                    </Button>
                  )}
                  {props.current.rights.includes("Ts") && (
                    <Button 
                      component={Link} 
                      to="/entries"
                      style={{color: 'white'}}  
                      sx={{ my: 2, color: 'white', display: 'block' }}
                    >
                      {t("entries")}
                    </Button>
                  )}
                  <Badge
                    color="secondary"
                    badgeContent={props.tasks}
                    invisible={props.tasks === 0}
                  >
                    <Button 
                      component={Link} 
                      to="/cycle"
                      style={{color: 'white'}}  
                      sx={{ my: 2, color: 'white', display: 'block' }}
                    >
                      {t("job")}
                    </Button>
                  </Badge>
                </div>
              )}
              { props && props.addons > 0 && props.current && props.current.rights.includes("Ad") &&
                (
                  <Button 
                    color="secondary"
                    component={Link}
                    to="/addons"
                    style={{color: 'white'}}
                    sx={{ my: 2, color: 'white', display: 'block' }}
                    >
                      {t("addons")}
                    </Button>
                )              
             }                            
          </Box>

          { props && props.tasks > 0 && 
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>                          
            <IconButton component={Link} to="/cycle" size="large">
            <Badge 
              color="secondary"
              badgeContent={props.tasks}
            >
              <InboxIcon style={{color: "white"}}/>
              </Badge>                
            </IconButton>                                      
          </Box>
          }

          <Box sx={{ flexGrow: 0}}>
            {props.name && (
              <IconButton
                aria-label="account of current user"
                arial-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                component={Link}
                to="/my"
                size="large">
                <Avatar>
                  {props.name
                    .split(" ")
                    .map((n) => n[0])
                    .join("")}
                </Avatar>
              </IconButton>              
           )}
          </Box>
        </Toolbar>        
    </AppBar>
  );
}

const mapStateToProps = (state) => {
  return {
    name: state.cloud && state.cloud.login && state.cloud.login.user ? state.cloud.login.user.name : null,
    current: state.cloud ? state.cloud.current : null,
    tasks:
      state.cloud && state.cloud.worklist ? state.cloud.worklist.length : null,
    addons:
      state.cloud && state.cloud.addons ? state.cloud.addons.filter((a) => a.active).length : null,
  }
}

export default connect(mapStateToProps, {})(MyAppBar)
