import { ListItem } from "@mui/material"
import ListItemText from "@mui/material/ListItemText"
import Paper from "@mui/material/Paper"
import List from "@mui/material/List"
import Container from "@mui/material/Container"
import Divider from "@mui/material/Divider"
import { connect } from "react-redux"
import { openAddon } from "../reducers/cloudReducer"
import LaunchIcon from '@mui/icons-material/Launch';

const AddonItem = ({ addon, onSelect }) => {
    const doSelect = async (addon) => {
        console.log("select addon", addon)
        await onSelect(addon)
    }

    return (
        <ListItem onClick={(e) => doSelect(addon)}>                              
            <LaunchIcon />  
            <ListItemText>{addon.name.fi}</ListItemText>
        </ListItem>
    )
}

const AddonList = (props) => {
    return (
        <Paper elevation={8}>
            <Container>
                <h3>Lisäosat</h3>
                <p>Lisäosat avautuvat uudessa välilehdessä.</p>
                <Divider />
                <List>
                    {props.addons &&
                        props.addons.filter((a) => a.active).map((addon) => (
                            <AddonItem
                                key={addon.id}
                                addon={addon}
                                onSelect={props.openAddon}
                            />
                        ))}
                </List>
            </Container>
        </Paper>
    )
}

const mapStateToProps = (state) => {
    return {
        addons: state.cloud ? state.cloud.addons : [],
    }
}

export default connect(mapStateToProps, { openAddon })(AddonList)