import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"

import { useTranslation } from "react-i18next"
import { getConfig, getUrl } from "../services/cloudService"
import axios from "axios"
import DayJS from "react-dayjs"

import Euro from "./Euro"
import SelectPeriod from "./SelectPeriod"
import NewFab from "./NewFab"

import Box from "@mui/material/Box"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableRow from "@mui/material/TableRow"
import Container from "@mui/material/Container"
import TextField from "@mui/material/TextField"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"

import AttachFileIcon from "@mui/icons-material/AttachFile"
import ReportIcon from "@mui/icons-material/Report"

const VoucherListItemLarge = ({ item }) => {
  const history = useHistory()
  const { t } = useTranslation("voucherType")

  return (
    <TableRow
      onClick={() => {
        history.push("/vouchers/" + item.id)
      }}
    >
      <TableCell>
        {item.sarja && item.sarja}
        {item.tunniste}
      </TableCell>
      <TableCell>
        {item.liitteita && <AttachFileIcon />}
        {item.huomio && <ReportIcon style={{ color: "red" }} />}
      </TableCell>
      <TableCell>
        <DayJS format="DD.MM.YYYY">{item.pvm}</DayJS>
      </TableCell>
      <TableCell>{t("" + item.tyyppi > 0 ? item.tyyppi : "0")}</TableCell>
      <TableCell>{item.laji}</TableCell>
      <TableCell align="right">
        <Euro value={item.summa} />
      </TableCell>
      <TableCell>{item.kumppani}</TableCell>
      <TableCell>{item.otsikko}</TableCell>
    </TableRow>
  )
}

const VoucherListItemSmall = ({ item }) => {
  const history = useHistory()
  const { t } = useTranslation("voucherType")

  return (   
    <TableRow
      onClick={() => {
      history.push("/vouchers/" + item.id)
      }}> 
      <TableCell>
      <DayJS format="DD.MM.">{item.pvm}</DayJS><br/>
        {item.liitteita && <AttachFileIcon />}  
        {item.huomio && <ReportIcon style={{ color: "red" }} />}              
      </TableCell>
      <TableCell align="right">
        <Euro value={item.summa} />
      </TableCell>      
      <TableCell>
        {item.kumppani}{item.kumppani &&<br/>}
        <Typography variant="body2">{item.otsikko}</Typography>
      </TableCell>
    </TableRow>
  )
}

const VoucherList = (props) => {
  const { t } = useTranslation("voucherList")
  const [data, setData] = useState([])
  const [filter, setFilter] = useState(null)
  const filtered =
    filter && filter.length > 0
      ? data.filter(
          (item) =>
            (item.otsikko &&
              item.otsikko.toLowerCase().includes(filter.toLowerCase())) ||
            (item.kumppani &&
              item.kumppani.toLowerCase().includes(filter.toLowerCase()))
        )
      : data

  useEffect(() => {
    if (props.period) {
      axios
        .get(
          getUrl(
            "/tositteet?alkupvm=" +
              props.period.alkaa +
              "&loppupvm=" +
              props.period.loppuu
          ),
          getConfig()
        )
        .then((response) => {
          setData(response.data)
        })
    }
  }, [props.updated, props.period])

  return (
        <Container text maxWidth="xl">
          <Grid container >
            <Grid item sm={3} xs={12}>
              <SelectPeriod />
            </Grid>
            <Grid item sm={9} xs={12}>
              <TextField
                id="outlined-basic"
                label={t("filter")}
                variant="outlined"
                value={filter}
                onChange={(event) => setFilter(event.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
          <h2>Tositteet</h2>
          <TableContainer>
            <Table sx={{display:{xs:'block', md:'none'}}}>
              <TableBody>
                {filtered.map((voucher) => (
                  <VoucherListItemSmall key={voucher.id} item={voucher} />
                ))}
              </TableBody>
            </Table>            
            
            <Table sx={{display:{xs:'none', md:'block'}}}>
              <TableBody>
                {filtered.map((voucher) => (
                  <VoucherListItemLarge key={voucher.id} item={voucher} />
                ))}
              </TableBody>
            </Table>

          </TableContainer>          
          <NewFab />
        </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    updated: state.cloud ? state.cloud.updated : null,
    period: state.cloud ? state.cloud.period : null,
  }
}

export default connect(mapStateToProps, {})(VoucherList)
