import cloudService from "../services/cloudService"

const cloudReducer = (store = null, action) => {
  switch (action.type) {
    case "LOGOUT":
      return null
    case "LOGIN":
      return {
        login: action.login,
        current: null,
        init: null,
        updated: null,
        worklist: null,
        addons: [],
        period: null,
        sector: null,
        report: "tulos/yleinen"
      }
    case "CLOUD":
      return {
        ...store,
        current: action.cloud,
        init: action.init,
        addons: action.addons,
        worklist: action.worklist,        
        period: action.init.tilikaudet.length
          ? action.init.tilikaudet[action.init.tilikaudet.length - 1]
          : null,
        sector: -1,
        updated: Date.now(),
        report: "tulos/yleinen"
      }
    case "PERIOD":
      return {
        ...store,
        period: action.period,
      }
    case "SECTOR":
      return {
        ...store,
        sector: action.sector,
      }
    case "RELOAD":
      return {
        ...store,
        worklist: action.worklist,
        updated: Date.now(),
      }
    case "REFRESH":
      return {
        ...store,
        updated: Date.now(),
      }
    case "REPORT":
      return {
        ...store,
        report: action.report
      }
    default:
      return store
  }
}

export const login = (email, password) => {
  return async (dispatch) => {
    const response = await cloudService.login(email, password)
    dispatch({
      type: "LOGIN",
      login: response,
    })
    // If user have access to one cloud only,
    // select this cloud
    if (response.clouds && response.clouds.length === 1) {
      selectCloud(response.clouds[0].id)
    }
  }
}

export const login2FA = (key, code) => {
  return async (dispatch) => {
    const response = await cloudService.login2FA(key, code)
    dispatch({
      type: "LOGIN",
      login: response
    })
  }
}


export const logout = () => {
  return async (dispatch) => {
    dispatch({
      type: "LOGOUT",
    })
  }
}

export const selectCloud = (cloudId) => {
  return async (dispatch) => {
    const cloud = await cloudService.authCloud(cloudId)
    const init = await cloudService.initCloud(cloud)
    const worklist = await cloudService.getWorklist()
    const addons = await cloudService.getAddonList();
    console.log('Addons', addons)
    dispatch({
      type: "CLOUD",
      cloud: cloud,
      init: init,
      worklist: worklist,
      addons: addons,
    })
  }
}

export const selectPeriod = (period) => {
  return async (dispatch) => {
    console.log("Period", period)
    dispatch({
      type: "PERIOD",
      period: period,
    })
  }
}

export const openAddon = (addon) => {
  return async (dispatch) => {
    console.log("Open addon", addon)
    const addonData = await cloudService.getAddonData(addon.id)
    const url = addonData.url + "?callId=" + addonData.callId
    window.open(url , "_blank")
  }

}

export const selectSector = (sector) => {
  return async (dispatch) => {
    console.log("Sector", sector)
    dispatch({
      type: "SECTOR",
      sector: sector,
    })
  }
}

export const selectReport = (report) => {
  return async (dispatch) => {
    console.log("Report", report)
    dispatch({
      type: "REPORT",
      report: report
    })
  }
}

export const reload = () => {
  return async (dispatch) => {
    console.log("RELOAD ME")
    const worklist = await cloudService.getWorklist()    
    dispatch({
      type: "RELOAD",
      worklist: worklist,
    })
  }
}

export const refresh = () => {
  return async (dispatch) =>
    dispatch({
      type: "REFRESH",
    })
}

export default cloudReducer
