// eslint-disable-next-line
import { green, yellow, blue, red } from "@mui/material/colors"

export const greenButton = {
  "&.MuiButton-root" : {
    color: "black",
    bgcolor: green[500],
    "&:hover": {
      bgcolor: green[700]
    },
    "&:disabled": {
      bgcolor: green[200]
    }
  }
}

export const yellowButton = {
  "&.MuiButton-root" : {
    color: "black",
    bgcolor: yellow[500],
    "&:hover": {
      bgcolor: yellow[700]
    },
    "&:disabled": {
      bgcolor: yellow[200]
    }
  }  
}

export const blueButton = {
  "&.MuiButton-root" : {
    color: "white",
    bgcolor: blue[500],
    "&:hover": {
      bgcolor: blue[700]
    },
    "&:disabled": {
      bgcolor: blue[200]
    }
  }  
}

export const redButton = {
  "&.MuiButton-root" : {
    color: "white",
    bgcolor: red[500],
    "&:hover": {
      bgcolor: red[700]
    },
    "&:disabled": {
      bgcolor: red[200]
    }
  }  
}