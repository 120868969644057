import axios from "axios"
import { Buffer } from "buffer"
let userToken
let cloudToken
let cloudUrl

export const getConfig = () => {
  return { headers: { authorization: `bearer ${cloudToken}` } }
}

export const getUrl = (path) => {
  return cloudUrl + path
}

export const putVoucher = async (data) => {
  const response = await axios.put(
    getUrl("/tositteet/" + data.id),
    data,
    getConfig()
  )
  return response.data
}

export const addComment = async (docId, comment) => {
  const response = await axios.post(
    getUrl("/tositteet/" + docId),
    {
      teksti: comment,
    },
    getConfig()
  )
  return response.status
}

const login = async (email, password) => {
  console.log("login with email", email)
  const response = await axios.post("/api/auth", {
    email: email,
    password: password,
    version: "WebKitsas",
    application: "WebKitsas",
    os: window.navigator.platform,
  })
  console.log(response.data)
  userToken=response.data.token
  return response.data
}

const login2FA = async(key, code) => {
  const response = await axios.post("/api/auth", {
    key: key,
    code: code,
    application: "WebKitsas",
    version: "WebKitsas",
    os: window.navigator.platform
  })
  console.log(response.data)
  userToken=response.data.token
  return response.data
}

const authCloud = async (cloudId) => {
  const response = await axios.get("/api/auth/" + cloudId,
    {headers: { authorization: `bearer ${userToken}`}})
  return response.data
}

const initCloud = async (cloud) => {
  console.log("Init cloud", cloud.url)
  cloudToken = cloud.token

  const url = new URL(cloud.url)
  cloudUrl = url.pathname

  const response = await axios.get(getUrl("/init"), getConfig())
  return response.data
}

const getWorklist = async () => {
  const response = await axios.get(getUrl("/kierrot/tyolista"), getConfig())
  return response.data
}

const getAddonList = async () => {
  try {
    const response = await axios.get("/api/v1/addons/", getConfig())
    return response.data 
  } catch (e) {
    return  []
  }
}

const getAddonData = async (id) => {
  const response = await axios.get("/api/v1/addons/" + id, getConfig())
  return response.data  
}

export const getPartnerNames = async () => {
  const response = await axios.get(getUrl("/kumppanit"), getConfig())
  const names = response.data.map((e) => e.nimi).filter((e) => e !== undefined)
  return names
}

export const attach = async (object) => {
  
  const data = object.data.substr( object.data.indexOf(",") + 1 )
  const file = Buffer.from(data,"base64")
  
  
  const filename = object.file.name.replace(/[^.\w]/g, "")
  const mimetype = object.file.type
  const config = {
    headers: {
      ...getConfig().headers,
      "Content-Type": mimetype,
      Filename: filename,
    },
    timeout: 30000,
  }
  const response = await axios.post(
    getUrl(mimetype === "application/pdf" ? "/liitteet?ocr=json" : "/liitteet"),
    file,
    config
  )
  return response
}

export const saveDocument = async (document) => {
  if (document.id) {
    return await axios.put(
      getUrl("/tositteet/" + document.id),
      document,
      getConfig()
    )
  } else {
    return await axios.post(getUrl("/tositteet"), document, getConfig())
  }
}

const toExport = { login, initCloud, getWorklist, login2FA, authCloud, getAddonList, getAddonData }
export default toExport
