import React from "react"
import { connect } from "react-redux"

import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"

import { selectReport } from "../reducers/cloudReducer"

import { useTranslation } from "react-i18next"

import { inLanguage } from "../utils/languageUtils"
 

const SelectReport = (props) => {
    const { t } = useTranslation("selectReport")    
    const { i18n } = useTranslation()
    const keys = Object.keys(props.settings).filter((key) => key.startsWith("tase/") || key.startsWith("tulos/"))
    const reports = []

    for(let key of keys) {        
        const settingStr = props.settings[key]        
        const settingObj = JSON.parse(settingStr)
        reports.push({key: key, name: settingObj.nimi, form: settingObj.muoto})
    }
    console.log(reports)

    return (
        <FormControl variant="outlined" fullWidth>
            <InputLabel>{t("report")}</InputLabel>
            <Select 
                fullWidth
                value={props.report}
                onChange={(event) => props.selectReport(event.target.value)}
            >                
                { reports.map((r) => (
                    <MenuItem value={r.key} key={r.key}>
                      {inLanguage(r.name, i18n.language)} / {inLanguage(r.form, i18n.language)}   
                    </MenuItem>
                ))}
            </Select>            
        </FormControl>
    )    
}

const mapStateToProps = (state) => {
    return {
        report : state.cloud ? state.cloud.report : "tulos/yleinen",
        settings: state.cloud && state.cloud.init ? state.cloud.init.asetukset : {}        
    }
}

export default connect(mapStateToProps, {selectReport}) (SelectReport)
